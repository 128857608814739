import React, { memo, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CharacteristicsImage from '../../../assets/img/sections/home/caracteristicas.png';

const List = memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { list } = useSelector((state) => state.homeSection3);

    useEffect(() => {
        dispatch.homeSection3.getAll();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div
                className="p-4 w-full h-72 bg-cover bg-no-repeat bg-center flex justify-end items-start"
                style={{backgroundImage: `url(${CharacteristicsImage})`}}>
                <button className="bg-primary text-white shadow-md hover:shadow-sm py-2 px-4 rounded">Ver imagen</button>
            </div>
            <div className="px-4">
                <div className="px-4 py-1 bg-white rounded shadow -mt-8">
                    <div className="w-full my-2 flex justify-between items-center">
                        <h2 className="text-2xl py-4">Sección 3 (Caracteristicas)</h2>
                        <button
                            onClick={() => history.push('/home-section-3/new')}
                            className="bg-primary text-white shadow-md hover:shadow-sm py-2 px-4 rounded">Agregar item</button>
                    </div>
                    <table className="w-full">
                        <thead className="text-left">
                            <th className="py-4">Titulo</th>
                            <th className="py-4">Acciones</th>
                        </thead>
                        <tbody>
                            {list.map((item) =>
                                <tr key={item.id}>
                                    <td className="py-4">
                                        <Link to={`/home-section-3/${item.id}`}>{item.title}</Link>
                                    </td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            className="text-primary cursor-pointer mx-2"
                                            onClick={() => history.push(`/home-section-3/${item.id}`)} />
                                        {/* <FontAwesomeIcon
                                            icon={RemoveIcon}
                                            className="text-red-500 cursor-pointer mx-2"
                                            onClick={() => dispatch.mainSlider.deleteSlide(slide.id)} /> */}
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
});

export default List;
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { preownedBanner1Schema } from '../../../formSchemas';
import { useImage } from '../../../hooks';
import PreownedBanner1 from '../../../assets/img/sections/seminuevos/banner-1.png';

const SLUG = 'preowned-banner-1';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(preownedBanner1Schema), mode: 'all' });
    const dispatch = useDispatch();
    const { loading, itemSelected, itemUpdatedSuccessfully } = useSelector((state) => state.preownedBanner1);
    const { imageSrc: mobileImageUrl, onImageChange: onMobileImageChange, error: mobileImageError } = useImage({ maxSize: 1 });
    const { imageSrc: desktopImageUrl, onImageChange: onDesktopImageChange, error: desktopImageError } = useImage({ maxSize: 1 });

    const onSubmit = useCallback((data) => {
        const formData = new FormData();
        const { mobileImage, desktopImage, ...noImageProperties } = data;

        formData.append('mobileImage',
            mobileImage[0] ? new Blob([mobileImage[0], { type: mobileImage[0].type }]) : itemSelected?.mobileImage);
        formData.append('desktopImage',
            desktopImage[0] ? new Blob([desktopImage[0], { type: desktopImage[0].type }]) : itemSelected?.desktopImage);
        
        for (const key in noImageProperties) {
            formData.append(key, noImageProperties[key]);
        }

        const callback = () => {
            setTimeout(() => {
                dispatch.preownedBanner1.setItemUpdatedSuccessfully(false);
            }, 3000);
        };

        dispatch.preownedBanner1.updateSectionData({ slug: SLUG, data: formData, callback }); 
    }, [dispatch, itemSelected]);

    const onImageContainerClick = useCallback((elementId) => {
        document.getElementById(elementId).click();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (itemSelected) {
            reset({
                title: itemSelected.title,
                subtitle: itemSelected.subtitle
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        dispatch.preownedBanner1.getSectionData(SLUG);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                className="w-full h-72 bg-cover bg-no-repeat bg-center"
                style={{backgroundImage: `url(${PreownedBanner1})`}} />
            <div className="px-4">
                <div className="px-4 py-1 bg-white rounded shadow -mt-8">
                    <h2 className="text-3xl py-4 font-bold text-primaryDark">Banner 1 (Seminuevos)</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            label="Titulo"
                            name="title"
                            ref={register}
                            error={errors?.title?.message}
                            placeholder="Escribe el titulo del banner" />
                        
                        <TextField
                            label="Subtitulo"
                            name="subtitle"
                            ref={register}
                            error={errors?.title?.message}
                            placeholder="Escribe el subtitulo del banner" />

                        <div className="flex my-8">
                            <div className="image-container w-1/2 px-4 flex flex-col items-center text-center">
                                <div
                                    className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                                    style={{backgroundImage: `url(${mobileImageUrl || itemSelected?.mobileImage})`}}
                                    onClick={() => onImageContainerClick('mobileImage')}>
                                    <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                        Sube tu imagen (Click aquí)
                                    </div>
                                </div>
                                <span className="text-xs text-left text-red-500 mt-2 pr-6">{mobileImageError}</span>
                                <input
                                    className="hidden"
                                    id="mobileImage"
                                    type="file"
                                    name="mobileImage"
                                    ref={register()}
                                    onChange={onMobileImageChange}
                                    accept="image/jpeg, image/jpg" />
                            </div>
                            <div className="image-container w-1/2 px-4 flex flex-col items-center text-center">
                                <div
                                    className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                                    style={{backgroundImage: `url(${desktopImageUrl})`}}
                                    onClick={() => onImageContainerClick('desktopImage')}>
                                    <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                        Sube tu imagen (Click aquí)
                                    </div>
                                </div>
                                <span className="text-xs text-left text-red-500 mt-2 pr-6">{desktopImageError}</span>
                                <input
                                    className="hidden"
                                    id="desktopImage"
                                    type="file"
                                    name="desktopImage"
                                    ref={register()}
                                    onChange={onDesktopImageChange}
                                    accept="image/jpeg, image/jpg" />
                            </div>
                        </div>

                        {itemUpdatedSuccessfully && <div className="p-4 bg-green-500 text-white rounded my-8">Banner editado satisfactoriamente.</div>}

                        {!itemUpdatedSuccessfully && <div className="flex justify-end w-full my-2">
                            {/* <button
                                type="button"
                                className="py-2 px-6 ml-2 border border-red-500 text-red-500 rounded text-white"
                                disabled={loading}>Cancelar</button> */}
                            <button
                                type="submit"
                                className={`${loading ? 'bg-gray-400' : 'bg-primary'} py-2 px-6 ml-2 rounded text-white`}
                                disabled={loading}>
                                {loading ? 'Guardando...' : 'Guardar'}
                            </button>
                        </div>}
                    </form>
                </div>
            </div>
        </>
    );
});

export default Form;
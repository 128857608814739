import { createUser, getAllUsers, updateUser, deleteUser } from '../../api';

const model = {
    state: {
        loading: false,
        selectedUser: null,
        users: [],
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setUsers(state, payload) {
            return {
                ...state,
                users: payload,
                loading: false
            }
        },
        setSelectedUser(state, payload) {
            return {
                ...state,
                selectedUser: payload,
            }
        },
    },
    effects: (dispatch) => ({
        async getUsersData() {
            dispatch.users.setLoading(true);
            getAllUsers()
                .then((response) => {
                    dispatch.users.setUsers(response.data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.users.setLoading(false));
        },
        async updateUser({id, callback, ...user}) {
            dispatch.users.setLoading(true);
            updateUser(id, user)
                .then(() => {
                    dispatch.users.getUsersData();
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err?.request?.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.users.setLoading(false));
        },
        async createUser({callback, ...user}) {
            dispatch.users.setLoading(true);
            createUser(user)
                .then(() => {
                    dispatch.users.getUsersData();
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err?.request?.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.users.setLoading(false));
        },
        async deleteUser({id, callback}) {
            dispatch.users.setLoading(true);
            deleteUser(id)
                .then(() => {
                    dispatch.users.getUsersData();
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err?.request?.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.users.setLoading(false));
        },
    })
}

export default model;
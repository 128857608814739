import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'main-slider';

/**
 * @description Gets all main slides from api
 * @author Isaac Vazquez
*/
export const getAllMainSlides = () => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        API_URL + '',
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Get an specific main slider from api
 * @param {uuid} id Slider id
 * @author Isaac Vazquez
*/
export const getMainSlideById = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

// { title, subtitle, secondaryText, mobileImage, desktopImage }
/**
 * @description Creates new slider for main slider
 * @author Isaac Vazquez
*/
export const saveSlide = (data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.post(
        API_URL + '',
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            }
        }
    );
}

/**
 * @description Updates new slider for main slider
 * @author Isaac Vazquez
*/
export const updateSlide = (id, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/${id}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            }
        }
    );
}

/**
 * @description Updates new slider for main slider
 * @param {UUID} id Slider id
 * @author Isaac Vazquez
*/
export const deleteSlide = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.delete(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactAdvisorSchema } from '../../../formSchemas';
import ContactAdvisorImage from '../../../assets/img/sections/servicios/contacta-asesor.png';

const SLUG = 'contact-advisor';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(ContactAdvisorSchema), mode: 'all' });
    const dispatch = useDispatch();
    const { loading, itemSelected, itemUpdatedSuccessfully } = useSelector((state) => state.contactAdvisor);

    const onSubmit = useCallback((data) => {

        const callback = () => {
            setTimeout(() => {
                dispatch.contactAdvisor.setItemUpdatedSuccessfully(false);
            }, 3000);
        };

        dispatch.contactAdvisor.updateSectionData({ slug: SLUG, data, callback }); 
    }, [dispatch]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                title: itemSelected.title,
                text: itemSelected.text
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        dispatch.contactAdvisor.getSectionData(SLUG);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                className="p-4 w-full h-72 bg-cover bg-no-repeat bg-center flex justify-end items-start"
                style={{backgroundImage: `url(${ContactAdvisorImage})`}}>
            </div>
            <div className="px-4">
                <div className="px-4 py-1 bg-white rounded shadow -mt-8">
                    <h2 className="text-3xl py-4 font-bold text-primaryDark">Contacta un asesor (Banner - Servicios)</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            label="Titulo"
                            name="title"
                            ref={register}
                            error={errors?.title?.message}
                            placeholder="Escribe el titulo del slide" />

                        <TextField
                            label="Texto"
                            name="text"
                            ref={register}
                            error={errors?.text?.message}
                            placeholder="Escribe el texto para la sección 4" />

                        {itemUpdatedSuccessfully && <div className="p-4 bg-green-500 text-white rounded my-8">Banner editado satisfactoriamente.</div>}
                        
                        {!itemUpdatedSuccessfully && <div className="flex justify-end w-full my-2">
                            <button
                                type="button"
                                className="py-2 px-8 ml-2 bg-red-500 text-white"
                                disabled={loading}>Cancelar</button>
                            <button
                                type="submit"
                                className="py-2 px-8 ml-2 bg-primary text-white"
                                disabled={loading}>
                                {loading ? 'Guardando' : 'Guardar'}
                            </button>
                        </div>}
                    </form>
                </div>
            </div>
        </>
    );
});

export default Form;
import React, { memo, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faEdit, faTrash as RemoveIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const List = memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { list } = useSelector((state) => state.models);

    useEffect(() => {
        dispatch.models.getAllModels();
        // eslint-disable-next-line
    }, [])

    return (
        <div className="px-4 py-1 bg-white rounded shadow">

            <div className="w-full my-2 flex justify-between items-center">
                <h2 className="text-2xl py-4">Modelos</h2>
                <button
                    onClick={() => history.push('/models/new')}
                    className="bg-primary text-white shadow-md hover:shadow-sm py-2 px-4 rounded">Agregar item</button>
            </div>
            <table className="w-full">
                <thead className="text-left">
                    <tr>
                        <th className="py-4">Modelo</th>
                        <th className="py-4">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((model) =>
                        <tr key={model.id}>
                            <td className="py-4">
                                <Link to={`/models/${model.id}`}>{model.model}</Link>
                            </td>
                            <td>
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    className="text-primary cursor-pointer mx-2"
                                    onClick={() => history.push(`/models/${model.id}`)} />
                                    {/* onClick={() => dispatch.mainSlider.deleteSlide(model.id)} */}
                                <FontAwesomeIcon
                                    icon={RemoveIcon}
                                    className="text-red-500 cursor-pointer mx-2" />
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </div>
    );
});

export default List;
import { updateSimpleSection, getSectionData } from '../../api';

const model = {
    state: {
        loading: false,
        itemSelected: null,
        itemUpdatedSuccessfully: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemUpdatedSuccessfully(state, payload) {
            return {
                ...state,
                itemUpdatedSuccessfully: payload
            }
        },
    },
    effects: (dispatch) => ({
        async updateSectionData({ slug, data, callback }) {
            dispatch.mainBannerServices.setLoading(true);
            updateSimpleSection(slug, data)
                .then(() => {
                    dispatch.mainBannerServices.setItemUpdatedSuccessfully(true);
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.mainBannerServices.setLoading(false));
        },
        async getSectionData(slug) {
            dispatch.mainBannerServices.setLoading(true);
            getSectionData(slug)
                .then(({data}) => {
                    dispatch.mainBannerServices.setItemSelected(data.data);
                }).catch((err) => {
                    console.error('Error', err);
                    console.log(err);
                    if (err?.request?.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.mainBannerServices.setLoading(false));
        }
    })
}

export default model;
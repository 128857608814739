import { getAllMainSlides, saveSlide, getMainSlideById, updateSlide, deleteSlide } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        slideCreatedSuccessfull: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setSlideCreatedSuccessfull(state, payload) {
            return {
                ...state,
                slideCreatedSuccessfull: payload
            }
        }
    },
    effects: (dispatch) => ({
        async getAll() {
            dispatch.mainSlider.setLoading(true);
            getAllMainSlides()
                .then(({ data }) => {
                    dispatch.mainSlider.setList(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.mainSlider.setLoading(false));
        },
        async saveSlide({ data, callback }, rootState) {
            dispatch.mainSlider.setLoading(true);
            saveSlide(data)
                .then(({ data }) => {
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.mainSlider.setLoading(false));
        },
        async updateSlide({ id, data, callback }, rootState) {
            dispatch.mainSlider.setLoading(true);
            updateSlide(id, data)
                .then(({ data }) => {
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.mainSlider.setLoading(false));
        },
        async deleteSlide(id, rootState) {
            dispatch.mainSlider.setLoading(true);
            deleteSlide(id)
            .then(({ data }) => {
                    console.log(data);
                    dispatch.mainSlider.getAll();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.mainSlider.setLoading(false));
        },
        async getSlideById(id, rootState) {
            getMainSlideById(id)
                .then(({ data }) => {
                    dispatch.mainSlider.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        }
    })
}

export default model;
import React, { memo, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit.svg';

const List = memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { list } = useSelector((state) => state.financingBanner);

    useEffect(() => {
        dispatch.homeSection2.getAll();
        dispatch.financingBanner.getAllFinancingBannerItems();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="p-4">
                <header className="pl-8 pr-4 py-6 bg-white shadow mb-4">
                    <h1 className="text-3xl text-primaryDark font-bold">Solicita financiamiento</h1>
                </header>
                <section>
                    {list.map((item) =>
                        <div key={item.id} className="flex justify-between items-center px-4 my-3 bg-primaryExtraLight shadow font-bold text-primaryDark text-lg">
                            <p className="py-4 flex items-center">
                                <div
                                    style={{backgroundImage: `url(${item.mobileImage})`}}
                                    className="h-24 w-40 bg-cover bg-no-repeat bg-center mr-8"/>
                                <Link to={`/financing-banner/${item.id}`}>{item.model?.model}</Link>
                            </p>
                            <div>
                                <button
                                    onClick={() => history.push(`/financing-banner/${item.id}`)}
                                    className="flex items-center font-bold ml-8">
                                    <span className="mr-4">Editar</span>
                                    <EditIcon />
                                </button>
                            </div>
                        </div>)}
            </section>
            </div>
        </>
    );
});

export default List;
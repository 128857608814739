import React, { memo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HomeCharacteristicSchema } from '../../../formSchemas';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(HomeCharacteristicSchema), mode: 'all' });
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, itemSelected } = useSelector((state) => state.homeSection3);
    const { id } = useParams();

    const onSubmit = useCallback((data) => {
        const formData = new FormData();
        const { mobileImage, desktopImage, ...noImageProperties } = data;

        if (mobileImage[0]) formData.append('mobileImage', new Blob([mobileImage[0], { type: mobileImage[0].type }]));
        if (desktopImage[0]) formData.append('desktopImage', new Blob([desktopImage[0], { type: desktopImage[0].type }]));
        
        for (const key in noImageProperties) {
            formData.append(key, noImageProperties[key]);
        }

        const callback = () => {
            reset();
            history.push('/home-section-3');
        };

        if (id) dispatch.homeSection3.updateCharacteristic({ id, data: formData, callback }); 
        else dispatch.homeSection3.saveCharacteristic({ data: formData, callback });
    }, [dispatch, history, id, reset]);

    useEffect(() => {
        if (id) dispatch.homeSection3.getCharacteristicById(id);
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                title: itemSelected.title,
                description: itemSelected.description,
                slogan: itemSelected.slogan
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    return (
        <div className="px-4 py-1 bg-white rounded shadow">
            <h2 className="text-2xl py-4">
                {id ? 'Editar slide' : 'Nuevo slide'}
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label="Titulo"
                    name="title"
                    ref={register}
                    error={errors?.title?.message}
                    placeholder="Escribe el titulo" />

                <TextField
                    label="Descripción"
                    name="description"
                    ref={register}
                    error={errors?.description?.message}
                    placeholder="Escribe el descripción" />
                
                <TextField
                    label="Slogan"
                    name="slogan"
                    ref={register}
                    error={errors?.slogan?.message}
                    placeholder="Escribe el slogan" />

                <div className="flex my-8">
                    <div className="w-1/2 px-4">
                        {/* <img src={itemSelected?.mobileImage} alt="" srcset=""  className="rounded" /> */}
                        <label htmlFor="mobileImage" className="font-bold">Imagen para dispositivos pequeños</label>
                        <input type="file" id="mobileImage" name="mobileImage" ref={register} />
                    </div>

                    <div className="w-1/2 px-4">
                        {/* <img src={itemSelected?.desktopImage} alt="" srcset="" className="rounded" /> */}
                        <label htmlFor="desktopImage" className="font-bold">Imagen para dispositivos pequeños</label>
                        <input type="file" id="desktopImage" name="desktopImage" ref={register} />
                    </div>
                </div>

                <div className="flex justify-end w-full my-2">
                    <button
                        type="button"
                        className="py-2 px-6 ml-2 border border-red-500 text-red-500 rounded text-white"
                        disabled={loading}>Cancelar</button>
                    <button
                        type="submit"
                        className="py-2 px-6 ml-2 bg-primary rounded text-white"
                        disabled={loading}>
                        {loading ? 'Guardando' : 'Guardar'}
                    </button>
                </div>
            </form>
        </div>
    );
});

export default Form;
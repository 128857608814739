import React, { memo, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit.svg';
import { ReactComponent as TrashIcon } from '../../../assets/svg/trash.svg';

const List = memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { list } = useSelector((state) => state.mainSlider);

    useEffect(() => {
        dispatch.mainSlider.getAll();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="p-4">
                <header className="pl-8 pr-4 py-6 bg-white shadow mb-4 flex justify-between items-center">
                    <h1 className="text-3xl text-primaryDark font-bold">Slider principal <span className="font-normal">(máx. 5)</span></h1>
                    <button className="px-6 py-3 bg-primary font-bold text-white flex items-center">
                        <span className="mr-2">Agregar nuevo slide</span>
                        <PlusIcon />
                    </button>
                </header>
                <section>
                    {list.map((slide) =>
                        <div key={slide.id} className="flex justify-between items-center px-4 my-3 bg-primaryExtraLight shadow font-bold text-primaryDark text-lg">
                            <p className="py-4 flex items-center">
                                <div
                                    style={{backgroundImage: `url(${slide.mobileImage})`}}
                                    className="h-24 w-40 bg-cover bg-no-repeat bg-center mr-8"/>
                                <Link to={`/main-slider/${slide.id}`}>{slide.title}</Link>
                            </p>
                            <div className="flex text-lg">
                                <button
                                    onClick={() => dispatch.mainSlider.deleteSlide(slide.id)}
                                    className="flex items-center font-bold">
                                    <span className="mr-4 text-red-500">Borrar</span>
                                    <TrashIcon />
                                </button>
                                <button
                                    onClick={() => history.push(`/main-slider/${slide.id}`)}
                                    className="flex items-center font-bold ml-8">
                                    <span className="mr-4">Editar</span>
                                    <EditIcon />
                                </button>
                            </div>
                        </div>)}
                </section>
            </div>
        </>
    );
});

export default List;
import { getAllModels, createModel, updateModel, getModelById } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        itemUpdatedSuccessfull: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemUpdatedSuccessfull(state, payload) {
            return {
                ...state,
                itemUpdatedSuccessfull: payload
            }
        }
    },
    effects: (dispatch) => ({
        async getModelById(id, rootState) {
            getModelById(id)
                .then(({ data }) => {
                    dispatch.models.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        },
        async updateModel({ id, data }, rootState) {
            dispatch.models.setLoading(true);
            updateModel(id, data)
                .then((response) => {
                    console.log(response);
                    dispatch.models.setLoading(false);
                    dispatch.models.setItemUpdatedSuccessfull(true);
                }).catch((err) => {
                    console.error('Error', err);
                    dispatch.models.setLoading(false);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        },
        async createModel(data, rootState) {
            dispatch.models.setLoading(true);
            createModel(data)
                .then((response) => {
                    console.log(response);
                    dispatch.models.setLoading(false);
                    dispatch.models.setItemUpdatedSuccessfull(true);
                }).catch((err) => {
                    console.error('Error', err);
                    dispatch.models.setLoading(false);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        },
        async getAllModels() {
            dispatch.models.setLoading(true);
            getAllModels()
                .then(({ data }) => {
                    dispatch.models.setList(data);
                    dispatch.models.setLoading(false);
                }).catch((err) => {
                    console.error('Error', err);
                    dispatch.models.setLoading(false);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        }
    })
}

export default model;
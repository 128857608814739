import React, { memo } from "react";
import { ReactComponent as AcuraLogo } from '../../assets/svg/white-logo.svg';

import {
    Login
} from '../../components';

import './Auth.css';

const Auth = memo(() => {
    return (
        <div className="auth-container flex h-screen">
            <section className="auth-container__bg px-16 py-24 w-2/5 text-white relative flex flex-col justify-between">
                <AcuraLogo />
                <p className="auth-quote leading-8">
                    Llevamos 30 años obsesionados con crear marquinas increíbles. Lo hemos logrando con un equipo ingenioso y creativo que hoy traza las lineas del futuro; siempre preparados para convertir el auto que sueñas en una experiencia al volante.” 
                </p>
                <h1 className="font-bold uppercase text-2xl">Administrador de contenidos</h1>
                {/* <footer className="w-full bg-primary p-8 absolute bottom-0 flex items-center justify-between">
                    <h2 className="bg__title text-white text-xl tracking-wide ml-8">ADMINISTRADOR DE CONTENIDOS</h2>
                </footer> */}
            </section>
            <main className="auth-container__form w-3/5 flex justify-center items-center px-16 py-24">
                <Login />
            </main>
        </div>
    );
});

export default Auth;
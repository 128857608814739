import { useState } from 'react';

const useImage = ({maxSize}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [error, setImageError] = useState(null);

    const onImageChange = ({target}) => {
        setIsLoading(true);
        const files = target.files;
        if (files && files[0]) {
            const reader = new FileReader();
            
            reader.onload = function (e) {
                // const fileSizeInMB = files[0].size/1024/1024;

                // fileSizeInMB <= maxSize
                if (true) {
                    const image = new Image();
                    image.src = e.target.result;
                    
                    image.onload = function () {
                        setImageSrc(this.src);
                        setImageError('');
                    }
                } else {
                    setImageError(`Es necesario subir una imagen en formato JPG o PNG no mayor a ${maxSize}mb`);
                }
                setIsLoading(false);
            };
            
            reader.readAsDataURL(files[0]);
        }
    }
    
    return { onImageChange, imageSrc, error, isLoading };
}

export default useImage;
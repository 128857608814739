import { updateDetailBanner1Item, getDetailBanner1Item, getAllDetailBanner1Items } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        itemUpdatedSuccessfully: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemUpdatedSuccessfully(state, payload) {
            return {
                ...state,
                itemUpdatedSuccessfully: payload
            }
        },
    },
    effects: (dispatch) => ({
        async getAllDetailBanner1Items() {
            dispatch.detailBanner1.setLoading(true);
            getAllDetailBanner1Items()
                .then(({ data }) => {
                    dispatch.detailBanner1.setList(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.detailBanner1.setLoading(false));
        },
        async updateDetailBanner1Item({ id, data }) {
            dispatch.detailBanner1.setLoading(true);
            updateDetailBanner1Item(id, data)
                .then(() => {
                    dispatch.detailBanner1.setItemUpdatedSuccessfully(true);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.detailBanner1.setLoading(false));
        },
        async getDetailBanner1Item(id) {
            dispatch.detailBanner1.setLoading(true);
            getDetailBanner1Item(id)
                .then(({data}) => {
                    dispatch.detailBanner1.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    console.log(err);
                    if (err?.request?.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.detailBanner1.setLoading(false));
        }
    })
}

export default model;
import { loginRequest } from "../../api";

const auth = {
    state: {
        token: null,
        user: null,
        invalidCredentials: false
    },
    reducers: {
        setAuthInfo(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        setInvalidCredentialsError(state, payload) {
            return {
                ...state,
                invalidCredentials: payload
            }
        },
        logout(state, payload) {
            localStorage.removeItem('acura-session');
            return {
                ...state,
                user: null
            }
        }
    },
    effects: (dispatch) => ({
        async login({ username, password }, rootState) {
            loginRequest({ username, password })
            .then(({ data }) => {
                    localStorage.setItem('acura-session', JSON.stringify(data));
                    dispatch.auth.setInvalidCredentialsError(false);
                    dispatch.auth.setAuthInfo({
                        user: data.user,
                        token: data.access_token
                    });
                }).catch((error) => {
                    console.error(error);
                    dispatch.auth.setInvalidCredentialsError(true);
                })
        }
    })
};

export default auth;
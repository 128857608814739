import React, { memo, useEffect, useCallback, useState } from "react";
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '../../shared';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HomeSecondarySliderSchema } from '../../../formSchemas';
import { useLazyImage } from '../../../hooks';
import SliderBannerImage from '../../../assets/img/sections/servicios/banner-slider.png';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as TrashIcon } from '../../../assets/svg/trash.svg';

const Form = memo(() => {
    const { handleSubmit, register, errors, control, reset } = useForm({ resolver: yupResolver(HomeSecondarySliderSchema), mode: 'all' });
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { itemSelected, loading, itemUpdatedSuccessfull } = useSelector((st) => st.servicesSlider);
    const [ deletedImages, setDeletedImages ] = useState([]);

    const onSubmit = useCallback((data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                data.images.forEach((image, index) => {
                    if (!image.id || (image.id && image.mobileImage.length)) {
                        formData.append(image.id, image.mobileImage.length ? new Blob([image.mobileImage[0]]) : null);
                    }
                });
            } else {
                formData.append(key, data[key]);
            }
        })
        
        formData.append('deletedImages', JSON.stringify(deletedImages));
        dispatch.servicesSlider.updateServicesSliderItem({ id, data: formData });
    }, [dispatch, id, deletedImages]);

    useEffect(() => {
        if (itemUpdatedSuccessfull) {
            setTimeout(() => {
                dispatch.servicesSlider.setItemUpdatedSuccessfull(false);
                history.push('/services-slider');
            }, 3000)
        }

        // eslint-disable-next-line
    }, [itemUpdatedSuccessfull])

    useEffect(() => {
        if (itemSelected) {
            reset({
                title: itemSelected.title,
                secondaryText: itemSelected.secondaryText,
                images: itemSelected.images.map((item) => {
                    return {
                        id: item.id,
                        mobileImage: item.mobileImage
                    }
                })
            });
        }
        // eslint-disable-next-line
    }, [itemSelected])

    useEffect(() => {
        if (id) {
            dispatch.servicesSlider.getServicesSliderItemById(id);
        }
        // eslint-disable-next-line
    }, [id]);

    return (
        <>
            <div
                className="w-full h-72 bg-cover bg-no-repeat bg-center"
                style={{backgroundImage: `url(${SliderBannerImage})`}} />
            
            <div className="px-4 -mt-8">
                <div className="px-4 py-1 bg-white rounded shadow">
                    <h2 className="text-3xl py-4 font-bold text-primaryDark">Slider (Servicios)</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            label="Titulo"
                            name="title"
                            ref={register}
                            error={errors?.title?.message}
                            placeholder="Escribe el titulo del slide" />

                        <TextField
                            label="Texto secundario"
                            name="secondaryText"
                            ref={register}
                            error={errors?.secondaryText?.message}
                            placeholder="Escribe el subtitulo del slide" />
                        
                        <SliderImages
                            control={control}
                            register={register}
                            itemSelected={itemSelected}
                            setDeletedImages={setDeletedImages} />
                        
                        {itemUpdatedSuccessfull && <div className="p-4 bg-green-500 text-white rounded my-8">Slide editado satisfactoriamente.</div>}

                        {!itemUpdatedSuccessfull && <div className="flex justify-end w-full my-2">
                            <button
                                type="button"
                                className="py-2 px-6 ml-2 border border-red-500 text-red-500 rounded text-white"
                                disabled={loading}>Cancelar</button>
                            <button
                                type="submit"
                                className="py-2 px-6 ml-2 bg-primary rounded text-white"
                                disabled={loading}>
                                {loading ? 'Guardando' : 'Guardar'}
                            </button>
                        </div>}
                    </form>
                </div>
            </div>
        </>
    );
});

const SliderImages = memo(({ control, register, itemSelected, setDeletedImages }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'images'
    });
    const [ images, setImages ] = useState([]);
    const [ imagesErrors, setImagesErrors ] = useState([]);
    const { loadImage } = useLazyImage();

    const addImage = useCallback(() => {
        const imagesArray = images.slice();
        imagesArray.push('empty');
        setImages((st) => [...imagesArray]);
        setImagesErrors((st) => new Array(imagesArray.length));
        append({ id: null, mobileImage: null });
    }, [append, images, setImages, setImagesErrors]);

    const removeImage = (index, imageId) => {
        console.log(imageId);
        const imagesArray = images.slice();
        imagesArray.splice(index, 1);
        setImages((st) => [...imagesArray]);
        if (imageId) setDeletedImages((st) => [...st, imageId]);
        remove(index);
    }

    const onImageContainerClick = useCallback((imageIndex) => {
        document.getElementById(`mobileImage${imageIndex}`).click();
        
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (itemSelected) {
            setImages(itemSelected.images.map((image) => image.mobileImage));
            setImagesErrors(itemSelected.images.map(() => ''));
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    const onImageLoad = (e, index) => {
        loadImage(e, 1)
            .then(imageSrc => {
                const imagesArray = images.slice();
                imagesArray[index] = imageSrc;
                setImages((st) => [...imagesArray]);
                const currentErrors = imagesErrors;
                currentErrors[index] = null;
                setImagesErrors((st) => [...currentErrors]);
            }).catch(error => {
                const currentErrors = imagesErrors;
                currentErrors[index] = error;
                setImagesErrors((st) => [...currentErrors]);
                console.error(error);
            });
    }

    return (
        <div className="my-8">
            <div className="flex justify-between items-center">
                <p className="font-bold text-primaryDark text-xl">Imagenés</p>
                
                <button
                    type="button"
                    onClick={addImage}
                    className="px-6 py-3 bg-primary font-bold text-white flex items-center">
                    <span className="mr-2">Agregar imagen</span>
                    <PlusIcon />
                </button>
            </div>

            {fields.map((field, index) => (
                <div className="flex items-center my-4" key={`image${index}`}>
                    <button
                        type="button"
                        className={`${images.length === 1 && 'invisible'} mx-4`}
                        onClick={() => removeImage(index, field.id)}>
                        <TrashIcon />
                    </button>
                    <div className="image-container">
                        <input type="text" className="hidden" name={`images[${index}].id`} ref={register()} defaultValue={field.id} />
                        <div
                            className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                            style={{backgroundImage: `url(${images[index] || field.mobileImage})`}}
                            onClick={() => onImageContainerClick(index)}>
                            <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                Sube tu imagen (Click aquí)
                            </div>
                        </div>
                        <span className="text-xs text-left text-red-500 mt-2 pr-6">{imagesErrors[index]}</span>
                        <input
                            className="hidden"
                            id={`mobileImage${index}`}
                            type="file"
                            name={`images[${index}].mobileImage`}
                            onChange={(e) => onImageLoad(e, index)}
                            ref={register()}
                            accept="image/jpeg, image/jpg" />
                    </div>
                </div>
            ))}
        </div>
    );
});

export default Form;
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModelMediaSchema } from '../../../formSchemas';
import MediaImage from '../../../assets/img/sections/detalle/media.png';
import { useImage } from '../../../hooks';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset, watch } = useForm({ resolver: yupResolver(ModelMediaSchema), mode: 'all' });
    const dispatch = useDispatch();
    const { loading, itemSelected, itemUpdatedSuccessfull } = useSelector((state) => state.detailMedia);
    const { id } = useParams();
    const history = useHistory();
    const { imageSrc: videoThumbnail, onImageChange: onVideoThumbnailChange, error: videoThumbnailError } = useImage({ maxSize: 1 });
    const { imageSrc: mobileImageUrl, onImageChange: onMobileImageChange, error: mobileImageError } = useImage({ maxSize: 1 });
    const { imageSrc: desktopImageUrl, onImageChange: onDesktopImageChange, error: desktopImageError } = useImage({ maxSize: 1 });

    const onSubmit = useCallback((data) => {
        console.log(data);
        const formData = new FormData();
        formData.append('type', data.type);

        if (data.type === 'video') {
            formData.append('videoUrl', data.videoUrl);
            if (data.videoThumbnail.length) formData.append('videoThumbnail',
                    data.videoThumbnail[0] ? new Blob([data.videoThumbnail[0], { type: data.videoThumbnail[0].type }]) : itemSelected?.videoThumbnail);
        } else {
            if (data.mobileImage.length) formData.append('mobileImage',
                    data.mobileImage[0] ? new Blob([data.mobileImage[0], { type: data.mobileImage[0].type }]) : itemSelected?.mobileImage);
            if (data.desktopImage.length) formData.append('desktopImage',
                    data.desktopImage[0] ? new Blob([data.desktopImage[0], { type: data.desktopImage[0].type }]) : itemSelected?.desktopImage);
        }

        dispatch.detailMedia.updateMedia({ id, data: formData }); 
    }, [dispatch, id, itemSelected]);

    const onImageContainerClick = useCallback((elementId) => {
        document.getElementById(elementId).click();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (itemUpdatedSuccessfull) {
            setTimeout(() => {
                dispatch.detailMedia.setItemUpdatedSuccessfull(false);
                history.push('/detail-media');
            }, 3000);
        }
        // eslint-disable-next-line
    }, [itemUpdatedSuccessfull]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                type: itemSelected.videoUrl ? 'video' : 'image',
                videoUrl: itemSelected.videoUrl
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        if (id) {
            dispatch.detailMedia.getMediaByModel(id);
        }
        // eslint-disable-next-line
    }, [id]);

    console.log(itemSelected);

    return (
        <>
            <div
                className="p-4 w-full h-72 bg-cover bg-no-repeat bg-center flex justify-end items-start"
                style={{backgroundImage: `url(${MediaImage})`}}>
                {/* <button className="bg-primary text-white shadow-md hover:shadow-sm py-2 px-4 rounded">Ver imagen</button> */}
            </div>
            <div className="px-4">
                <div className="px-4 py-1 bg-white rounded shadow -mt-8">
                    <h2 className="text-3xl font-bold text-primaryDark py-4">Media</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <select
                            name="type"
                            className="py-3 px-6 border border-gray-400"
                            ref={register}>
                            <option value="">Selecciona un modelo</option>
                            <option value="image">Imagen</option>
                            <option value="video">Video</option>
                        </select>

                        {watch('type') === 'video' &&
                            <div>
                                <TextField
                                    id="videoUrl"
                                    name="videoUrl"
                                    ref={register}
                                    error={errors?.videoUrl?.message}
                                    label="Video Url"
                                    placeholder="Ingresa la url del video" />
                                
                                <div className="my-8">
                                    <div className="image-container w-1/2 flex flex-col text-center">
                                        <div
                                            style={{backgroundImage: `url(${videoThumbnail || itemSelected?.videoThumbnailUrl})`}}
                                            className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                                            onClick={() => onImageContainerClick('videoThumbnail')}>
                                            <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                                Sube tu imagen (Click aquí)
                                            </div>
                                        </div>
                                        <span className="text-xs text-left text-red-500 mt-2 pr-6">{videoThumbnailError}</span>
                                        <input
                                            className="hidden"
                                            id="videoThumbnail"
                                            type="file"
                                            name="videoThumbnail"
                                            ref={register()}
                                            onChange={onVideoThumbnailChange}
                                            accept="image/jpeg, image/jpg" />
                                    </div>
                                </div>
                            </div>
                        }

                        {watch('type') === 'image' &&
                            <div>
                                <div className="flex my-8">
                                    <div className="image-container w-1/2 px-4 flex flex-col items-center text-center">
                                        <div
                                            className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                                            style={{backgroundImage: `url(${mobileImageUrl || itemSelected?.mobileImage})`}}
                                            onClick={() => onImageContainerClick('mobileImage')}>
                                            <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                                Sube tu imagen - Mobile (Click aquí)
                                            </div>
                                        </div>
                                        <span className="text-xs text-left text-red-500 mt-2 pr-6">{mobileImageError}</span>
                                        <input
                                            className="hidden"
                                            id="mobileImage"
                                            type="file"
                                            name="mobileImage"
                                            ref={register()}
                                            onChange={onMobileImageChange}
                                            accept="image/jpeg, image/jpg" />
                                    </div>
                                    <div className="image-container w-1/2 px-4 flex flex-col items-center text-center">
                                        <div
                                            className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                                            style={{backgroundImage: `url(${itemSelected?.desktopImage || desktopImageUrl})`}}
                                            onClick={() => onImageContainerClick('desktopImage')}>
                                            <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                                Sube tu imagen - Escritorio (Click aquí)
                                            </div>
                                        </div>
                                        <span className="text-xs text-left text-red-500 mt-2 pr-6">{desktopImageError}</span>
                                        <input
                                            className="hidden"
                                            id="desktopImage"
                                            type="file"
                                            name="desktopImage"
                                            ref={register()}
                                            onChange={onDesktopImageChange}
                                            accept="image/jpeg, image/jpg" />
                                    </div>
                                </div>
                            </div>
                        }

                        {itemUpdatedSuccessfull && <div className="p-4 bg-green-500 text-white rounded my-8">Media editado satisfactoriamente.</div>}

                        {!itemUpdatedSuccessfull && <div className="flex justify-end w-full my-2">
                            <button
                                type="button"
                                className="bg-red-500 py-2 px-8 ml-2 text-white"
                                disabled={loading}>
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className={`${loading ? 'bg-gray-400' : 'bg-primary'} py-2 px-8 ml-2 text-white`}
                                disabled={loading}>
                                {loading ? 'Guardando...' : 'Guardar'}
                            </button>
                        </div>}
                    </form>
                </div>
            </div>
        </>
    );
});

export default Form;
import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionButtons from './ActionsButtons';
import UsersForm from './Form';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { DialogContext } from '../../../context/dialog';

const List = () => {
    const { setDialogContent } = useContext(DialogContext);
    const dispatch = useDispatch();
    const { users } = useSelector(state => state.users);
    
    const addUserHandler = () => {
        setDialogContent(
            <UsersForm />
        );
    }

    useEffect(() => {
        dispatch.users.getUsersData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="p-4">
            <header className="pl-8 pr-4 py-6 mb-6 bg-white shadow mb-4 flex justify-between items-center">
                <h1 className="text-3xl text-primaryDark font-bold">Usuarios</h1>

                <button
                    onClick={addUserHandler}
                    className="ml-4 px-6 py-2 bg-primary font-bold text-white flex items-center">
                    <span className="mr-2">Nuevo usuario</span>
                    <PlusIcon />
                </button>
            </header>
            <section>
                {users?.map((user) =>
                    <div key={user.id} className="flex justify-between items-center px-4 my-3 bg-primaryExtraLight shadow font-bold text-primaryDark text-lg">
                        <p className="py-4 flex items-center">{user.email}</p>
                        <ActionButtons user={user} />
                    </div>)}
            </section>
        </div>
    );
};

export default List;
import PropTypes from 'prop-types';
import './Dialog.css';

const Dialog = ({ children, open }) => (
    <div className={`dialog ${open && 'dialog--open'}`}>{children}</div>
);

Dialog.propTypes = {
    children: PropTypes.element,
    open: PropTypes.bool
}

Dialog.defaultProps = {
    children: null,
    open: false
}

export default Dialog;
import { getAllFinancingBannerItems, getFinancingBannerItem, updateFinancingBannerItem } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        itemUpdatedSuccessfully: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemUpdatedSuccessfully(state, payload) {
            return {
                ...state,
                itemUpdatedSuccessfully: payload
            }
        },
    },
    effects: (dispatch) => ({
        async getAllFinancingBannerItems() {
            dispatch.financingBanner.setLoading(true);
            getAllFinancingBannerItems()
                .then(({ data }) => {
                    dispatch.financingBanner.setList(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.financingBanner.setLoading(false));
        },
        async getFinancingBannerItem(slug) {
            dispatch.financingBanner.setLoading(true);
            getFinancingBannerItem(slug)
                .then(({data}) => {
                    dispatch.financingBanner.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    console.log(err);
                    if (err?.request?.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.financingBanner.setLoading(false));
        },
        async updateFinancingBannerItem({ id, data, callback }) {
            dispatch.financingBanner.setLoading(true);
            updateFinancingBannerItem(id, data)
                .then(() => {
                    dispatch.financingBanner.setItemUpdatedSuccessfully(true);
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.financingBanner.setLoading(false));
        },
    })
}

export default model;
import React, { memo, useCallback, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/svg/white-logo-admin.svg';
import { ReactComponent as LogoutIcon } from '../../assets/svg/logout.svg';
import { ReactComponent as ServerIcon } from '../../assets/svg/server.svg';
import { ReactComponent as CarIcon } from '../../assets/svg/car.svg';
import { ReactComponent as InfoIcon } from '../../assets/svg/info.svg';
import { ReactComponent as NoteIcon } from '../../assets/svg/note.svg';
import { ReactComponent as CalendarIcon } from '../../assets/svg/calendar.svg';
import { ReactComponent as BannerIcon } from '../../assets/svg/banner.svg';
import { ReactComponent as CirclesIcon } from '../../assets/svg/circles.svg';
import { ReactComponent as UserIcon } from '../../assets/svg/user.svg';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    MainSliderList,
    MainSliderForm,
    Section2List,
    Section2Form,
    HomeSection3List,
    HomeSection3Form,
    HomeSection4Form,
    CustomerOpinionsList,
    CustomerOpinionsForm,
    BannerAppointmentForm,
    HomeSecondarySliderForm,
    HomeSecondarySliderList,
    ModelsList,
    ModelsForm,
    DetailSliderList,
    DetailSliderForm,
    DetailBanner1Form,
    FinancingBannerList,
    FinancingBannerForm,
    DetailBanner1List,
    DetailMediaList,
    DetailMediaForm,
    MainBannerServicesForm,
    ContactAdvisorForm,
    SparePartsBannerForm,
    ServicesSliderList,
    ServicesSliderForm,
    MainBannerKnowUsForm,
    BlogBannerKnowUsForm,
    PreownedBanner1Form,
    PreownedBanner2Form,
    UsersList
} from '../../components/admin';

import './Admin.css';

const Admin = memo(() => {
    const { user } = useSelector((state) => state.auth);
    const history = useHistory();
    const dispatch = useDispatch();
    const [ openedMenuOptions, setOpenedMenuOptions ] = useState([]);

    const onMenuItemClick = useCallback((menuItem) => {
        if (openedMenuOptions.includes(menuItem)) {
            const menuItemsToPush = openedMenuOptions.filter((item) => item !== menuItem);
            setOpenedMenuOptions(menuItemsToPush);
            return;
        }

        setOpenedMenuOptions((openedItems) => [...openedItems, menuItem]);
    }, [openedMenuOptions, setOpenedMenuOptions]);

    return (
        <div className="admin_container w-screen h-screen max-w-screen overflow-hidden bg-gray-100">
            <header className="w-full bg-black flex justify-between items-center">
                <div className="px-8">
                    <Logo />
                </div>

                <div className="flex">
                    <p className="px-6 text-white py-4 text">{user.username}</p>
                    <button className="px-8 py-4 bg-red-500 text-white flex" onClick={() => dispatch.auth.logout()}>
                        <span className="mr-2">Cerrar sesión</span>
                        <LogoutIcon />
                    </button>
                </div>
            </header>

            <div className="flex">
                <nav className="admin_container__nav min-h-full shadow bg-white relative px-4 h-screen">
                    <ul className="py-6 menu">
                        {/* <li>    
                            <p
                                className="transition duration-300 ease-in-out py-2 pl-4 border-l-4 hover:border-primary cursor-pointer"
                                onClick={() => history.push('/models')}>
                                <FontAwesomeIcon icon={faCar} className="mr-4" />
                                Modelos
                            </p>
                        </li> */}
                        <li className={`${ openedMenuOptions.includes('home') && 'menu__item--opened' } menu__item`}>    
                            <p
                                className={`py-2 font-bold text-primary text-lg cursor-pointer`}
                                onClick={() => onMenuItemClick('home')}>
                                Inicio
                            </p>
                            <ul className="pl-1 text-left">
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/main-slider')}>
                                    <ServerIcon />
                                    <span className="ml-2">Slider principal</span>
                                </li>
                                <li
                                    className="cursor-pointer my-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/home-section-2')}>
                                    <CarIcon />
                                    <span className="ml-2">Modelos</span>
                                </li>
                                <li
                                    className="cursor-pointer my-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/home-secondary-slider')}>
                                    <InfoIcon />
                                    <span className="ml-2">Info Acura GDL</span>
                                </li>
                                <li
                                    className="cursor-pointer my-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/customer-opinions')}>
                                    <NoteIcon />
                                    <span className="ml-2">Opinión de clientes</span>
                                </li>
                            </ul>
                        </li>
                        <li className={`${ openedMenuOptions.includes('detail') && 'menu__item--opened' } menu__item`}>    
                            <p
                                className={`py-2 font-bold text-primary text-lg cursor-pointer`}
                                onClick={() => onMenuItemClick('detail')}>
                                Detalle
                            </p>
                            <ul className="pl-1">
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/detail-slider')}>
                                    <ServerIcon />
                                    <span className="ml-2">Slider principal</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/financing-banner')}>
                                    <CarIcon />
                                    <span className="ml-2">Solicita financiamiento</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/detail-media')}>
                                    <CirclesIcon />
                                    <span className="ml-2">Media</span>
                                </li>
                            </ul>
                        </li>
                        <li className={`${ openedMenuOptions.includes('services') && 'menu__item--opened' } menu__item`}>    
                            <p
                                className={`py-2 font-bold text-primary text-lg cursor-pointer`}
                                onClick={() => onMenuItemClick('services')}>
                                Servicios
                            </p>
                            <ul className="pl-1">
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/main-banner-services')}>
                                    <ServerIcon />
                                    <span className="ml-2">Principal</span>
                                </li>
                            </ul>
                        </li>
                        <li className={`${ openedMenuOptions.includes('know-us') && 'menu__item--opened' } menu__item`}>    
                            <p
                                className={`py-2 font-bold text-primary text-lg cursor-pointer`}
                                onClick={() => onMenuItemClick('know-us')}>
                                Conocenos
                            </p>
                            <ul className="pl-1">
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/main-banner-know-us')}>
                                    <ServerIcon />
                                    <span className="ml-2">Principal</span>
                                </li>
                            </ul>
                        </li>
                        <li className={`${ openedMenuOptions.includes('banners') && 'menu__item--opened' } menu__item`}>    
                            <p
                                className={`py-2 font-bold text-primary text-lg cursor-pointer`}
                                onClick={() => onMenuItemClick('banners')}>
                                Banners
                            </p>
                            <ul className="pl-1">
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/banner-appointment')}>
                                    <CalendarIcon />
                                    <span className="ml-2">Agendar cita</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/home-section-4')}>
                                    <BannerIcon />
                                    <span className="ml-2">Banner 1</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/detail-banner-1')}>
                                    <BannerIcon />
                                    <span className="ml-2">Banner 2</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/contact-advisor')}>
                                    <BannerIcon />
                                    <span className="ml-2">Banner 3</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/spare-parts-banner')}>
                                    <BannerIcon />
                                    <span className="ml-2">Banner 4</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/services-slider')}>
                                    <BannerIcon />
                                    <span className="ml-2">Banner 5</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/blog-banner-know-us')}>
                                    <BannerIcon />
                                    <span className="ml-2">Banner 6</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/preowned-banner-1')}>
                                    <BannerIcon />
                                    <span className="ml-2">Banner 7</span>
                                </li>
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/preowned-banner-2')}>
                                    <BannerIcon />
                                    <span className="ml-2">Banner 8</span>
                                </li>
                            </ul>
                        </li>
                        <li className={`${ openedMenuOptions.includes('admin') && 'menu__item--opened' } menu__item`}>    
                            <p
                                className={`py-2 font-bold text-primary text-lg cursor-pointer`}
                                onClick={() => onMenuItemClick('admin')}>
                                Admin
                            </p>
                            <ul className="pl-1">
                                <li
                                    className="cursor-pointer mt-4 mb-8 rounded flex opacity-50 hover:opacity-100 hover:font-bold text-primary"
                                    onClick={() => history.push('/users')}>
                                    <UserIcon />
                                    <span className="ml-2">Usuarios</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                
                <main className="">
                    <Switch>
                        <Route exact path="/main-slider" component={MainSliderList} />
                        <Route exact path="/main-slider/new" component={MainSliderForm} />
                        <Route exact path="/main-slider/:id" component={MainSliderForm} />
                        <Route exact path="/home-section-2" component={Section2List} />
                        <Route exact path="/home-section-2/new" component={Section2Form} />
                        <Route exact path="/home-section-2/:id" component={Section2Form} />
                        <Route exact path="/home-section-3" component={HomeSection3List} />
                        <Route exact path="/home-section-3/new" component={HomeSection3Form} />
                        <Route exact path="/home-section-3/:id" component={HomeSection3Form} />
                        <Route exact path="/home-section-4" component={HomeSection4Form} />
                        <Route exact path="/customer-opinions" component={CustomerOpinionsList} />
                        <Route exact path="/customer-opinions/:id" component={CustomerOpinionsForm} />
                        <Route exact path="/banner-appointment" component={BannerAppointmentForm} />
                        <Route exact path="/home-secondary-slider" component={HomeSecondarySliderList} />
                        <Route exact path="/home-secondary-slider/:id" component={HomeSecondarySliderForm} />
                        <Route exact path="/models" component={ModelsList} />
                        <Route exact path="/models/new" component={ModelsForm} />
                        <Route exact path="/models/:id" component={ModelsForm} />
                        <Route exact path="/detail-slider" component={DetailSliderList} />
                        <Route exact path="/detail-slider/:modelId/new" component={DetailSliderForm} />
                        <Route exact path="/detail-slider/:modelId/edit/:slideId" component={DetailSliderForm} />
                        <Route exact path="/detail-banner-1" component={DetailBanner1List} />
                        <Route exact path="/detail-banner-1/:id" component={DetailBanner1Form} />
                        <Route exact path="/financing-banner" component={FinancingBannerList} />
                        <Route exact path="/financing-banner/:id" component={FinancingBannerForm} />
                        <Route exact path="/detail-media" component={DetailMediaList} />
                        <Route exact path="/detail-media/:id" component={DetailMediaForm} />
                        <Route exact path="/main-banner-services" component={MainBannerServicesForm} />
                        <Route exact path="/contact-advisor" component={ContactAdvisorForm} />
                        <Route exact path="/spare-parts-banner" component={SparePartsBannerForm} />
                        <Route exact path="/services-slider" component={ServicesSliderList} />
                        <Route exact path="/services-slider/:id" component={ServicesSliderForm} />
                        <Route exact path="/main-banner-know-us" component={MainBannerKnowUsForm} />
                        <Route exact path="/blog-banner-know-us" component={BlogBannerKnowUsForm} />
                        <Route exact path="/preowned-banner-1" component={PreownedBanner1Form} />
                        <Route exact path="/preowned-banner-2" component={PreownedBanner2Form} />
                        <Route exact path="/users" component={UsersList} />

                        <Redirect to="/main-slider" />
                    </Switch>
                </main>
            </div>
        </div>
    );
});

export default Admin;
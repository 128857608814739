import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'home-secondary-slider';

/**
 * @description Gets a specific secondary slider item from api
 * @author Isaac Vazquez
*/
export const getHomeSecondarySliderItemById = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Updates a specific secondary slider item from api
 * @param {id} string
 * @author Isaac Vazquez
*/
export const updateHomeSecondarySliderItem = (id, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/${id}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            }
        }
    );
}

/**
 * @description Get all slider item from api
 * @author Isaac Vazquez
*/
export const getAllHomeSecondarySliderItems = () => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}
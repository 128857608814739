import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'model-slider';

/**
 * @description Gets all model slides from api
 * @author Isaac Vazquez
*/
export const getAllModelDetailSlides = (modelId) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${modelId}/all`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Creates model slides from api
 * @author Isaac Vazquez
*/
export const createModelDetailSlide = (data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.post(
        `${API_URL}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            }
        }
    );
}

/**
 * @description Updates model slides from api
 * @author Isaac Vazquez
*/
export const updateModelDetailSlide = (id, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/${id}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            }
        }
    );
}

/**
 * @description Gets an specific model slide from api
 * @author Isaac Vazquez
*/
export const getModelDetailSlideById = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Deletes an specific model slide from api
 * @author Isaac Vazquez
*/
export const deleteModelDetailSlideById = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.delete(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}
import React, { forwardRef, memo } from "react";

const TextField = memo(forwardRef((inputProps, ref) => {
    const { label, id, type, name, placeholder, error } = inputProps;

    return (
        <div className="input-container my-4">
            {!!label && <label htmlFor={id} className="py-4">{label}</label>}
            <input
                type={type}
                id={id}
                name={name}
                ref={ref}
                placeholder={placeholder}
                className={`${error && 'border-red-500'} mt-3 py-4 px-8 w-full border border-gray-400 rounded`} />
            {!!error && <p className="text-red-500 text-xs">{error}</p>}
        </div>
    )
}));

export default TextField;
import React, { memo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MainSliderSchema } from '../../../formSchemas';
import { useImage } from '../../../hooks';
import MainSliderImage from '../../../assets/img/sections/home/main-slider.png';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(MainSliderSchema), mode: 'all' });
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, itemSelected } = useSelector((state) => state.mainSlider);
    const { id } = useParams();
    const { onImageChange: onMobileImageChange, imageSrc: mobileImageSrc, error: mobileImageError } = useImage({ maxSize: 1 });
    const { onImageChange: onDesktopImageChange, imageSrc: desktopImageSrc, error: desktopImageError } = useImage({ maxSize: 1 });

    const onSubmit = useCallback((data) => {
        const formData = new FormData();
        const { mobileImage, desktopImage, ...noImageProperties } = data;

        if (mobileImage[0]) formData.append('mobileImage', new Blob([mobileImage[0], { type: mobileImage[0].type }]));
        if (desktopImage[0]) formData.append('desktopImage', new Blob([desktopImage[0], { type: desktopImage[0].type }]));
        
        for (const key in noImageProperties) {
            formData.append(key, noImageProperties[key]);
        }

        const callback = () => {
            history.push('/main-slider');
        };

        if (id) dispatch.mainSlider.updateSlide({ id, data: formData, callback }); 
        else dispatch.mainSlider.saveSlide({ data: formData, callback });
    }, [dispatch, history, id]);

    const onImageContainerClick = useCallback((elementId) => {
        document.getElementById(elementId).click();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (id) dispatch.mainSlider.getSlideById(id);
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                title: itemSelected.title,
                subtitle: itemSelected.subtitle,
                secondaryText: itemSelected.secondaryText,
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    return (
        <>
            <div
                className="p-4 w-full h-72 bg-cover bg-no-repeat bg-center flex justify-end items-start"
                style={{backgroundImage: `url(${MainSliderImage})`}}>
                {/* <button className="bg-primary text-white shadow-md hover:shadow-sm py-2 px-8">Ver imagen</button> */}
            </div> 
            <div className="px-4 -mt-8">
                <div className="px-4 py-1 bg-white rounded shadow">
                    <h2 className="text-3xl py-4 text-primaryDark font-bold">
                        {id ? 'Editar slide' : 'Nuevo slide'}
                    </h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            label="Titulo"
                            name="title"
                            ref={register}
                            error={errors?.title?.message}
                            placeholder="Escribe el titulo del slide" />

                        <TextField
                            label="Subtitulo"
                            name="subtitle"
                            ref={register}
                            error={errors?.subtitle?.message}
                            placeholder="Escribe el subtitulo del slide" />
                        
                        <TextField
                            label="Texto secundario"
                            name="secondaryText"
                            ref={register}
                            error={errors?.secondaryText?.message}
                            placeholder="Escribe el texto secundario del slide" />

                        <div className="flex my-8">
                            <div className="image-container w-1/2 px-4 flex flex-col items-center text-center">
                                <div
                                    className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                                    style={{backgroundImage: `url(${mobileImageSrc || itemSelected?.mobileImage})`}}
                                    onClick={() => onImageContainerClick('mobileImage')}>
                                    <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                        Sube tu imagen (Click aquí)
                                    </div>
                                </div>
                                <span className="text-xs text-left text-red-500 mt-2 pr-6">{mobileImageError}</span>
                                <input
                                    className="hidden"
                                    id="mobileImage"
                                    type="file"
                                    name="mobileImage"
                                    ref={register()}
                                    onChange={onMobileImageChange}
                                    accept="image/jpeg, image/jpg" />
                            </div>
                            <div className="image-container w-1/2 px-4 flex flex-col items-center text-center">
                                <div
                                    className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                                    style={{backgroundImage: `url(${desktopImageSrc})`}}
                                    onClick={() => onImageContainerClick('desktopImage')}>
                                    <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                        Sube tu imagen (Click aquí)
                                    </div>
                                </div>
                                <span className="text-xs text-left text-red-500 mt-2 pr-6">{desktopImageError}</span>
                                <input
                                    className="hidden"
                                    id="desktopImage"
                                    type="file"
                                    name="desktopImage"
                                    ref={register()}
                                    onChange={onDesktopImageChange}
                                    accept="image/jpeg, image/jpg" />
                            </div>
                        </div>

                        <div className="flex justify-end w-full my-2">
                            <button
                                type="button"
                                className="py-2 px-8 ml-2 bg-red-500 text-white text-white font-bold"
                                disabled={loading}
                                onClick={() => history.push('/main-slider')}>Cancelar</button>
                            <button
                                type="submit"
                                className="py-2 px-8 ml-2 bg-primary text-white font-bold"
                                disabled={loading}>
                                {loading ? 'Guardando' : 'Guardar'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
});

export default Form;
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'customer-opinions';

/**
 * @description Gets all customer opinions from api
 * @author Isaac Vazquez
*/
export const getAllCustomerOpinions = () => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        API_URL + '',
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Get an specific customer opinion from api
 * @param {uuid} id Customer opinion id
 * @author Isaac Vazquez
*/
export const getCustomerOpinionById = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Updates customer opinion
 * @author Isaac Vazquez
*/
export const updateCustomerOpinion = (id, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/${id}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        }
    );
}
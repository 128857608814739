import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BannerAppointmentSchema } from '../../../formSchemas';
import { useImage } from '../../../hooks';
import AppointmentsBannerImage from '../../../assets/img/sections/home/appointments-banner.png';

const SLUG = 'banner-appointment';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(BannerAppointmentSchema), mode: 'all' });
    const dispatch = useDispatch();
    const { loading, itemSelected, itemUpdatedSuccessfully } = useSelector((state) => state.bannerAppointment);
    const { onImageChange: onMobileImageChange, imageSrc: mobileImageSrc } = useImage({ maxSize: 1 });
    const { onImageChange: onDesktopImageChange, imageSrc: desktopImageSrc } = useImage({ maxSize: 1 });

    const onSubmit = useCallback((data) => {
        const formData = new FormData();
        const { mobileImage, desktopImage, ...noImageProperties } = data;

        formData.append('mobileImage',
            mobileImage[0] ? new Blob([mobileImage[0], { type: mobileImage[0].type }]) : itemSelected?.mobileImage);
        formData.append('desktopImage',
            desktopImage[0] ? new Blob([desktopImage[0], { type: desktopImage[0].type }]) : itemSelected?.desktopImage);
        
        for (const key in noImageProperties) {
            formData.append(key, noImageProperties[key]);
        }

        const callback = () => {
            setTimeout(() => {
                dispatch.bannerAppointment.setItemUpdatedSuccessfully(false);
                reset({
                    text: data.text
                });
            }, 3000);
        };

        dispatch.bannerAppointment.updateSectionData({ slug: SLUG, data: formData, callback }); 
    }, [dispatch, itemSelected, reset]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                text: itemSelected.text
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        dispatch.bannerAppointment.getSectionData(SLUG);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                className="w-full h-72 bg-cover bg-no-repeat bg-center"
                style={{backgroundImage: `url(${AppointmentsBannerImage})`}} />
            <div className="px-4">
                <div className="px-4 py-1 bg-white rounded shadow -mt-8">
                    <h2 className="text-3xl py-4 font-bold text-primaryDark">Banner (Agendar cita)</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            label="Texto"
                            name="text"
                            ref={register}
                            error={errors?.title?.message}
                            placeholder="Escribe el texto del banner" />

                        <div className="flex my-8">
                            <div className="w-1/2 px-4">
                                {(mobileImageSrc || itemSelected?.mobileImage) && <img src={mobileImageSrc || itemSelected?.mobileImage} alt="" className="rounded" />}
                                <label htmlFor="mobileImage" className="font-bold">Imagen para dispositivos pequeños</label>
                                <input type="file" id="mobileImage" name="mobileImage" ref={register} onChange={onMobileImageChange} />
                            </div>

                            <div className="w-1/2">
                                {(desktopImageSrc || itemSelected?.desktopImage) && <img src={desktopImageSrc || itemSelected?.desktopImage} alt="" className="rounded" />}
                                <label htmlFor="desktopImage" className="font-bold">Imagen para dispositivos pequeños</label>
                                <input type="file" id="desktopImage" name="desktopImage" ref={register} onChange={onDesktopImageChange}/>
                            </div>
                        </div>

                        {itemUpdatedSuccessfully && <div className="p-4 bg-green-500 text-white rounded my-8">Banner editado satisfactoriamente.</div>}

                        {!itemUpdatedSuccessfully && <div className="flex justify-end w-full my-2">
                            {/* <button
                                type="button"
                                className="py-2 px-6 ml-2 border border-red-500 text-red-500 rounded text-white"
                                disabled={loading}>Cancelar</button> */}
                            <button
                                type="submit"
                                className={`${loading ? 'bg-gray-400' : 'bg-primary'} py-2 px-6 ml-2 rounded text-white`}
                                disabled={loading}>
                                {loading ? 'Guardando...' : 'Guardar'}
                            </button>
                        </div>}
                    </form>
                </div>
            </div>
        </>
    );
});

export default Form;
import { getAllCustomerOpinions, updateCustomerOpinion, getCustomerOpinionById } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        itemUpdatedSuccessfull: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemUpdatedSuccessfull(state, payload) {
            return {
                ...state,
                itemUpdatedSuccessfull: payload
            }
        }
    },
    effects: (dispatch) => ({
        async getAllCustomerOpinions() {
            dispatch.customerOpinions.setLoading(true);
            getAllCustomerOpinions()
                .then(({ data }) => {
                    dispatch.customerOpinions.setList(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.customerOpinions.setLoading(false));
        },
        async updateCustomerOpinion({ id, data, callback }, rootState) {
            dispatch.customerOpinions.setLoading(true);
            updateCustomerOpinion(id, data)
                .then(({ data }) => {
                    dispatch.customerOpinions.setItemUpdatedSuccessfull(true);
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.customerOpinions.setLoading(false));
        },
        async getCustomerOpinionById(id, rootState) {
            getCustomerOpinionById(id)
                .then(({ data }) => {
                    dispatch.customerOpinions.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        }
    })
}

export default model;
import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginFormSchema } from '../../../formSchemas';
import { TextField } from '../../shared';

const Login = memo(() => {
    const { handleSubmit, register, errors } = useForm({ resolver: yupResolver(LoginFormSchema), mode: 'all' });
    const dispatch = useDispatch();
    const { invalidCredentials } = useSelector((state) => state.auth);

    const onSubmit = useCallback((data) => {
        dispatch.auth.login(data);
    }, [dispatch]);

    return (
        <form
            className="w-full h-full flex flex-col justify-center relative"
            onSubmit={handleSubmit(onSubmit)}>
            <p className="absolute top-0 right-0 text-gray-500">
                ¿Presentas algun problema? <strong className="text-primaryLight">Contacta a un administrador</strong>
            </p>
            <h1 className="text-xl text-primary my-2">Bienvenido(a), ingresa tus credenciales para comenzar.</h1>

            {invalidCredentials && <p className="text-white text-xl text-center p-4 bg-red-500 rounded-lg">Credenciales invalidas. Corrige y vuelve a intentarlo</p>}

            <TextField
                name="username"
                type="email"
                placeholder="Correo"
                label="Correo electronico*"
                error={errors?.username?.message}
                ref={register} />

            <TextField
                name="password"
                type="password"
                placeholder="Contraseña"
                label="Contraseña*"
                error={errors?.password?.message}
                ref={register} />

            <button
                type="submit"
                className="shadow-xl hover:shadow-md mt-4 bg-black py-3 w-1/2 text-white">Ingresar</button>
        </form>
    );
});

export default Login;
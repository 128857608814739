const useLazyImage = () => {

    const loadImage = ({target}, maxSize, maxWidth, maxHeight) => {
        const files = target.files;
        if (files && files[0]) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                
                reader.onload = function (e) {
                    const fileSizeInMB = files[0].size/1024/1024;
                    if (fileSizeInMB > maxSize) {
                        target.value = null;
                        reject(`Es necesario subir una imagen en formato JPG no mayor a ${maxSize}mb.`);
                    };

                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        /* if (maxWidth < image.width || maxHeight < image.height) {
                            target.value = null;
                            reject(`Las dimensiones máximas son de ${maxWidth}px${maxHeight}px.`);
                        }; */
                        resolve(this.src);
                    }

                    image.onerror = (error) => {
                        console.error(error);
                    }
                };
                
                reader.readAsDataURL(files[0]);
            });
        }
    }
    
    return {loadImage};
}

export default useLazyImage;
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'users';

/**
 * @description Gets all users
 * @author Isaac Vazquez
*/
export const getAllUsers = () => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        API_URL,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Updates specified user
 * @Param {String} id - User id
 * @Param {Object} data - User data to update
 * @Param {String} data.email - User's email
 * @Param {String} data.password - User's password
 * @author Isaac Vazquez
*/
export const updateUser = (id, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/update/${id}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Creates new user
 * @Param {Object} data - User data to update
 * @Param {String} data.email - User's email
 * @Param {String} data.password - User's password
 * @author Isaac Vazquez
*/
export const createUser = (data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.post(
        `${API_URL}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Deletes specified user
 * @Param {String} id - User id
 * @author Isaac Vazquez
*/
export const deleteUser = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.delete(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}
import React, { memo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomerOpinionSchema } from '../../../formSchemas';
import { format } from 'date-fns';
import CustomerOpinionsImage from '../../../assets/img/sections/home/customers-opinions.png';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(CustomerOpinionSchema), mode: 'all' });
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, itemSelected, itemUpdatedSuccessfull } = useSelector((state) => state.customerOpinions);
    const { id } = useParams();

    const onSubmit = useCallback((data) => {
        const callback = () => {
            setTimeout(() => {
                dispatch.customerOpinions.setItemUpdatedSuccessfull(false);
                reset();
                history.push('/customer-opinions');
            }, 3000)
        };

        dispatch.customerOpinions.updateCustomerOpinion({ id, data, callback }); 
    }, [dispatch, history, id, reset]);

    useEffect(() => {
        if (id) dispatch.customerOpinions.getCustomerOpinionById(id);
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                customer: itemSelected.customerName,
                quote: itemSelected.quote,
                date: format(new Date(itemSelected.date), "yyyy-MM-dd"),
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);
    
    useEffect(() => {
        return () => {
            dispatch.customerOpinions.setItemSelected(null);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                className="w-full h-72 bg-cover bg-no-repeat bg-center"
                style={{backgroundImage: `url(${CustomerOpinionsImage})`}} />
            <div className="px-4 -mt-8">
                <div className="px-4 py-1 bg-white rounded shadow">
                    <h2 className="text-3xl font-bold text-primaryDark py-4">Editar opinión</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            label="Cliente"
                            name="customer"
                            ref={register}
                            error={errors?.customer?.message}
                            placeholder="Escribe el nombre del cliente" />

                        <TextField
                            label="Opinión"
                            name="quote"
                            ref={register}
                            error={errors?.quote?.message}
                            placeholder="Escribe la opinión del cliente" />
                        
                        <TextField
                            label="Fecha"
                            name="date"
                            ref={register}
                            type="date"
                            error={errors?.date?.message && 'Campo requerido.'} />
                        
                        {itemUpdatedSuccessfull && <div className="p-4 bg-green-500 text-white rounded my-8">Opinión editada satisfactoriamente.</div>}

                        {!itemUpdatedSuccessfull && <div className="flex justify-end w-full my-2">
                            <button
                                type="button"
                                className="py-2 px-6 ml-2 border border-red-500 text-red-500 rounded text-white"
                                disabled={loading}
                                onClick={() => history.push('/customer-opinions')}>Cancelar</button>
                            <button
                                type="submit"
                                className={`${loading ? 'bg-gray-400' : 'bg-primary'} py-2 px-6 ml-2 rounded text-white`}
                                disabled={loading}>
                                {loading ? 'Guardando...' : 'Guardar'}
                            </button>
                        </div>}
                    </form>
                </div>
            </div>
        </>
    );
});

export default Form;
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'model';

/**
 * @description Gets all models from api
 * @author Isaac Vazquez
*/
export const getAllModels = () => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        API_URL,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Creates a model
 * @author Isaac Vazquez
*/
export const createModel = (data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.post(
        API_URL,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Updates a model
 * @author Isaac Vazquez
*/
export const updateModel = (id, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/${id}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Get an specific model by id
 * @author Isaac Vazquez
*/
export const getModelById = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DialogContext } from '../../../context/dialog';
import { Confirm } from '../../shared';
import UsersForm from './Form';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit.svg';
import { ReactComponent as TrashIcon } from '../../../assets/svg/trash.svg';

const ActionsButtons = ({ user }) => {
    const { setDialogContent } = useContext(DialogContext);
    const dispatch = useDispatch();
    const { selectedUser } = useSelector((state) => state.users);
    const { user: loggedUser } = useSelector((state) => state.auth);

    const editHandler = () => {
        dispatch.users.setSelectedUser(user);
    }

    const deleteHandler = () => {
        setDialogContent(
            <Confirm
                title={`Eliminar usuario: [${user.email}]`}
                message="Esta acción no se podrá revertir, ¿Estás seguro de continuar?"
                onAccept={() => {
                    dispatch.users.deleteUser({id: user.id, callback: () => {
                        setDialogContent(null);
                    }});
                }}
                onCancel={() => {
                    setDialogContent(null);
                }} />
        );
    }

    useEffect(() => {
        if (selectedUser) {
            setDialogContent(
                <UsersForm />
            );
        }
        // eslint-disable-next-line
    }, [selectedUser]);

    return (
        <>
            <div className="flex text-lg">
                {loggedUser?.sub !== user.id && <button
                    onClick={deleteHandler}
                    className="flex items-center font-bold">
                    <span className="mr-4 text-red-500">Borrar</span>
                    <TrashIcon />
                </button>}
                <button
                    type="button"
                    className="flex items-center font-bold ml-8"
                    onClick={editHandler}>
                    <span className="mr-4">Editar</span>
                    <EditIcon />
                </button>
            </div>
        </>
    );
};

ActionsButtons.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
    })
}

export default ActionsButtons;
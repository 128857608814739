import PropTypes from 'prop-types';

const Confirm = ({title, message, onAccept, onCancel}) => {
    return (
        <div className="w-2/5 bg-white px-6 py-6 shadow-lg rounded">
            <h4 className="text-2xl font-bold pb-4 border-b-2 border-gray">{title}</h4>
            <p className="py-8">{message}</p>

            <div className="flex justify-between">
                <button
                        type="button"
                        className="py-2 px-6 ml-2 border border-black text-black rounded"
                        onClick={onCancel}>Cancelar</button>
                <button
                    type="submit"
                    className="py-2 px-6 ml-2 bg-red-500 rounded text-white"
                    onClick={onAccept}>
                    {'Eliminar'}
                </button>
            </div>
        </div>
    );
}

Confirm.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default Confirm;
import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  AuthPage,
  AdminPage
} from './pages';

function App() {
  const { user } = useSelector((st) => st.auth);
  const dispatch = useDispatch();
  // const history = useHistory();
  
  useEffect(() => {
    // if (user) history.push('/');
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const authSession = localStorage.getItem('acura-session');
    if (authSession) {
      dispatch.auth.setAuthInfo(JSON.parse(authSession));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App font-lato">
      {!!user
          ? <Switch>
              <Route path="/" component={AdminPage} />

              <Redirect to="/main-slider" />
            </Switch>
          : <Switch>
              <Route path="/auth" component={AuthPage} />

              <Redirect to="/auth" />
            </Switch>
      }
    </div>
  );
}

export default App;

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'detail-banner-1';

/**
 * @description Gets all detail banner items from api
 * @author Isaac Vazquez
*/
export const getAllDetailBanner1Items = (modelId) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Gets detail banner item from api
 * @param {string} id
 * @author Isaac Vazquez
*/
export const getDetailBanner1Item = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Updates detail banner item
 * @param {string} id
 * @param {object} data
 * @author Isaac Vazquez
*/
export const updateDetailBanner1Item = (id, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/${id}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}
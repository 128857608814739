import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'home-model';

/**
 * @description Gets all items in section 2 from api
 * @author Isaac Vazquez
*/
export const getAllModelItems = () => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        API_URL,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Get an specific item in section 2 from api
 * @param {uuid} id Item id
 * @author Isaac Vazquez
*/
export const getModelItemById = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

// { title, subtitle, mobileImage, desktopImage }
/**
 * @description Creates new item in section 2
 * @author Isaac Vazquez
*/
export const saveModel = (data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.post(
        API_URL + '',
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            }
        }
    );
}

/**
 * @description Updates new item in section 2
 * @author Isaac Vazquez
*/
export const updateModelItem = (id, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/${id}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            }
        }
    );
}

/**
 * @description Updates an specific item in section 2
 * @param {UUID} id Item id
 * @author Isaac Vazquez
*/
export const deleteModel = (id) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.delete(
        `${API_URL}/${id}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}
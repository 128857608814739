import React, { memo, useEffect, useState, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit.svg';
import { ReactComponent as TrashIcon } from '../../../assets/svg/trash.svg';

const List = memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ selectedModel, setSelectedModel ] = useState();
    const { list: models } = useSelector((state) => state.models);
    const { list } = useSelector((state) => state.detailSlider);

    const onDeleteClick = useCallback((id) => {
        dispatch.detailSlider.deleteModelDetailSlideById({ id, selectedModel });
        // eslint-disable-next-line
    }, [selectedModel]);

    useEffect(() => {
        if (selectedModel) {
            dispatch.detailSlider.getAllModelDetailSlides(selectedModel)
        }
        // eslint-disable-next-line
    }, [selectedModel]);

    useEffect(() => {
        dispatch.models.getAllModels();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="p-4">
            <header className="pl-8 pr-4 py-6 bg-white shadow mb-4 flex justify-between items-center">
                <h1 className="text-3xl text-primaryDark font-bold">Slider principal <span className="font-normal">(máx. 5)</span></h1>
                <div className="flex">
                    <select
                        onChange={(e) => setSelectedModel(e.target.value)}
                        className="py-3 px-6 border border-gray-400">
                        <option value="">Selecciona un modelo</option>
                        {models.map((model) => <option key={model.id} value={model.id}>{model.model}</option>)}
                    </select>
                    {selectedModel && <button className="ml-4 px-6 py-3 bg-primary font-bold text-white flex items-center">
                        <span className="mr-2">Agregar nuevo slide</span>
                        <PlusIcon />
                    </button>}
                </div>
            </header>
            
            {selectedModel ? <section>
                        {list?.length ? list.map((slide) =>
                            <div key={slide.id} className="flex justify-between items-center px-4 my-3 bg-primaryExtraLight shadow font-bold text-primaryDark text-lg">
                                <p className="py-4 flex items-center">
                                    <div
                                        style={{backgroundImage: `url(${slide.mobileImage})`}}
                                        className="h-24 w-40 bg-cover bg-no-repeat bg-center mr-8"/>
                                    <Link to={`/detail-slider/${selectedModel}/edit/${slide.id}`}>{slide.title}</Link>
                                </p>
                                <div className="flex text-lg">
                                    <button
                                        onClick={() => onDeleteClick(slide.id)}
                                        className="flex items-center font-bold">
                                        <span className="mr-4 text-red-500">Borrar</span>
                                        <TrashIcon />
                                    </button>
                                    <button
                                        onClick={() => history.push(`/detail-slider/${selectedModel}/edit/${slide.id}`)}
                                        className="flex items-center font-bold ml-8">
                                        <span className="mr-4">Editar</span>
                                        <EditIcon />
                                    </button>
                                </div>
                            </div>) : null}
                {!list?.length && <p className="font-bold text-center py-4">No hay información disponible</p>}
            </section> : <div className="py-4 font-bold text-center">Selecciona un modelo para comenzar</div>}
        </div>
    );
});

export default List;
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'simple-sections';

/**
 * @description Updates section 4 text
 * @author Isaac Vazquez
*/
export const updateSimpleSection = (slug, data) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.put(
        `${API_URL}/${slug}`,
        data,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        }
    );
}

/**
 * @description Gets a specific item by slug
 * @author Isaac Vazquez
*/
export const getSectionData = (slug) => {
    const { access_token } = JSON.parse(localStorage.getItem('acura-session'));
    return axios.get(
        `${API_URL}/${slug}`,
        {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            }
        }
    );
}
import { getHomeSecondarySliderItemById, updateHomeSecondarySliderItem, getAllHomeSecondarySliderItems } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        itemUpdatedSuccessfull: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemUpdatedSuccessfull(state, payload) {
            return {
                ...state,
                itemUpdatedSuccessfull: payload
            }
        }
    },
    effects: (dispatch) => ({
        async getHomeSecondarySliderItemById(id, rootState) {
            getHomeSecondarySliderItemById(id)
                .then(({ data }) => {
                    dispatch.homeSecondarySlider.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        },
        async updateHomeSecondarySliderItem({ id, data }, rootState) {
            dispatch.homeSecondarySlider.setLoading(true);
            updateHomeSecondarySliderItem(id, data)
                .then((response) => {
                    console.log(response);
                    dispatch.homeSecondarySlider.setLoading(false);
                    dispatch.homeSecondarySlider.setItemUpdatedSuccessfull(true);
                }).catch((err) => {
                    console.error('Error', err);
                    dispatch.homeSecondarySlider.setLoading(false);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        },
        async getAllHomeSecondarySliderItems() {
            dispatch.homeSecondarySlider.setLoading(true);
            getAllHomeSecondarySliderItems()
                .then(({ data }) => {
                    dispatch.homeSecondarySlider.setList(data);
                    dispatch.homeSecondarySlider.setLoading(false);
                }).catch((err) => {
                    console.error('Error', err);
                    dispatch.homeSecondarySlider.setLoading(false);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        }
    })
}

export default model;
import { getAllModelDetailSlides, createModelDetailSlide, getModelDetailSlideById, updateModelDetailSlide, deleteModelDetailSlideById } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        slideUpdatedSuccessfull: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setSlideUpdatedSuccessfull(state, payload) {
            return {
                ...state,
                slideUpdatedSuccessfull: payload
            }
        }
    },
    effects: (dispatch) => ({
        async getAllModelDetailSlides(modelId) {
            dispatch.detailSlider.setLoading(true);
            getAllModelDetailSlides(modelId)
                .then(({ data }) => {
                    dispatch.detailSlider.setList(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.detailSlider.setLoading(false));
        },
        async createModelDetailSlide({ data }, rootState) {
            dispatch.detailSlider.setLoading(true);
            createModelDetailSlide(data)
            .then(({ data }) => {
                    dispatch.detailSlider.setSlideUpdatedSuccessfull(true);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.mainSlider.setLoading(false));
        },
        async getModelDetailSlideById(id, rootState) {
            getModelDetailSlideById(id)
                .then(({ data }) => {
                    dispatch.detailSlider.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        },
        async updateModelDetailSlide({ id, data }, rootState) {
            dispatch.detailSlider.setLoading(true);
            updateModelDetailSlide(id, data)
                .then(({ data }) => {
                    dispatch.detailSlider.setSlideUpdatedSuccessfull(true);
                    dispatch.detailSlider.setItemSelected(null);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.detailSlider.setLoading(false));
        },
        async deleteModelDetailSlideById({ id, selectedModel }, rootState) {
            console.log(selectedModel);
            dispatch.detailSlider.setLoading(true);
            deleteModelDetailSlideById(id)
                .then(({ data }) => {
                        console.log(data);
                        dispatch.detailSlider.getAllModelDetailSlides(selectedModel);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.detailSlider.setLoading(false));
        },
    })
}

export default model;
import * as yup from 'yup';
import { ErrorMessages } from '../enums';

const schema = yup.object().shape({
    title: yup.string().required(ErrorMessages.REQUIRED_FIELD),
    secondaryText: yup.string().required(ErrorMessages.REQUIRED_FIELD),
    images: yup.array().of(
        yup.object().shape({
            id: yup.string()
        })
    ),
});

export default schema;
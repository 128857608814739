import { getAllModelItems, saveModel, getModelItemById, updateModelItem, deleteModel } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        itemUpdatedSuccessfull: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemUpdatedSuccessfull(state, payload) {
            return {
                ...state,
                itemUpdatedSuccessfull: payload
            }
        }
    },
    effects: (dispatch) => ({
        async getAll() {
            dispatch.homeSection2.setLoading(true);
            getAllModelItems()
                .then(({ data }) => {
                    dispatch.homeSection2.setList(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.homeSection2.setLoading(false));
        },
        async saveModel({ data, callback }, rootState) {
            dispatch.homeSection2.setLoading(true);
            saveModel(data)
                .then(({ data }) => {
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.homeSection2.setLoading(false));
        },
        async updateModelItem({ id, data, callback }, rootState) {
            dispatch.homeSection2.setLoading(true);
            updateModelItem(id, data)
                .then(({ data }) => {
                    dispatch.homeSection2.setItemUpdatedSuccessfull(true);
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.homeSection2.setLoading(false));
        },
        async deleteModel(id, rootState) {
            dispatch.homeSection2.setLoading(true);
            deleteModel(id)
            .then(({ data }) => {
                    console.log(data);
                    dispatch.homeSection2.getAll();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.homeSection2.setLoading(false));
        },
        async getModelItemById(id, rootState) {
            getModelItemById(id)
                .then(({ data }) => {
                    dispatch.homeSection2.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        }
    })
}

export default model;
import React, { memo, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModelSchema } from '../../../formSchemas';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(ModelSchema), mode: 'all' });
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, itemSelected, itemUpdatedSuccessfull } = useSelector((state) => state.models);
    const { id } = useParams();

    const onSubmit = useCallback((data) => {
        if (id) {
            dispatch.models.updateModel({id, data});
        } else {
            dispatch.models.createModel(data);
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (itemUpdatedSuccessfull) {
            setTimeout(() => {
                reset({ model: '' });
                dispatch.models.setItemUpdatedSuccessfull(false);
                history.push('/models');
            }, 3000);
        }

        // eslint-disable-next-line
    }, [itemUpdatedSuccessfull])

    useEffect(() => {
        if (id) dispatch.models.getModelById(id);
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                model: itemSelected.model
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    return (
        <div className="px-4 py-1 bg-white rounded shadow">
            <h2 className="text-2xl py-4">
                {id ? 'Editar slide' : 'Nuevo slide'}
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label="Modelo"
                    name="model"
                    ref={register}
                    error={errors?.model?.message}
                    placeholder="Escribe el nombre del modelo" />

                {itemUpdatedSuccessfull && <div className="p-4 bg-green-500 text-white rounded my-8">Modelo {id ? 'editado' : 'guardado'} satisfactoriamente.</div>}
                
                {!itemUpdatedSuccessfull && <div className="flex justify-end w-full my-2">
                    <button
                        type="button"
                        className="py-2 px-6 ml-2 border border-red-500 text-red-500 rounded text-white"
                        disabled={loading}>Cancelar</button>
                    <button
                        type="submit"
                        className="py-2 px-6 ml-2 bg-primary rounded text-white"
                        disabled={loading}>
                        {loading ? 'Guardando' : 'Guardar'}
                    </button>
                </div>}
            </form>
        </div>
    );
});

export default Form;
import { getServicesSliderItemById, updateServicesSliderItem, getAllServicesSliderItems } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        itemUpdatedSuccessfull: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemUpdatedSuccessfull(state, payload) {
            return {
                ...state,
                itemUpdatedSuccessfull: payload
            }
        }
    },
    effects: (dispatch) => ({
        async getServicesSliderItemById(id, rootState) {
            getServicesSliderItemById(id)
                .then(({ data }) => {
                    dispatch.servicesSlider.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        },
        async updateServicesSliderItem({ id, data }, rootState) {
            dispatch.servicesSlider.setLoading(true);
            updateServicesSliderItem(id, data)
                .then((response) => {
                    console.log(response);
                    dispatch.servicesSlider.setLoading(false);
                    dispatch.servicesSlider.setItemUpdatedSuccessfull(true);
                }).catch((err) => {
                    console.error('Error', err);
                    dispatch.servicesSlider.setLoading(false);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        },
        async getAllServicesSliderItems() {
            dispatch.servicesSlider.setLoading(true);
            getAllServicesSliderItems()
                .then(({ data }) => {
                    dispatch.servicesSlider.setList(data);
                    dispatch.servicesSlider.setLoading(false);
                }).catch((err) => {
                    console.error('Error', err);
                    dispatch.servicesSlider.setLoading(false);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        }
    })
}

export default model;
import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '../components/shared/Dialog/Dialog';

export const DialogContext = createContext({});

const DialogProvider = ({ children }) => {
    const [dialogContent, setDialogContent] = useState(null);

    return (
        <DialogContext.Provider value={{setDialogContent}}>
            <Dialog open={!!dialogContent}>{dialogContent}</Dialog>
            {children}
        </DialogContext.Provider>
    );
}

DialogProvider.propTypes = {
    children: PropTypes.element.isRequired,
}

export default DialogProvider;
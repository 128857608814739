import { getAllCharacteristics, saveCharacteristic, getCharacteristicById, updateCharacteristic, deleteCharacteristic } from '../../api';

const model = {
    state: {
        loading: false,
        list: [],
        itemSelected: null,
        itemCreatedSuccessfull: false
    },
    reducers: {
        setLoading(state, payload) {
            return {
                ...state,
                loading: payload
            }
        },
        setList(state, payload) {
            return {
                ...state,
                list: payload || []
            }
        },
        setItemSelected(state, payload) {
            return {
                ...state,
                itemSelected: payload
            }
        },
        setItemCreatedSuccessfull(state, payload) {
            return {
                ...state,
                slideCreatedSuccessfull: payload
            }
        }
    },
    effects: (dispatch) => ({
        async getAll() {
            dispatch.homeSection3.setLoading(true);
            getAllCharacteristics()
                .then(({ data }) => {
                    dispatch.homeSection3.setList(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.homeSection3.setLoading(false));
        },
        async saveCharacteristic({ data, callback }, rootState) {
            dispatch.homeSection3.setLoading(true);
            saveCharacteristic(data)
                .then(({ data }) => {
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.homeSection3.setLoading(false));
        },
        async updateCharacteristic({ id, data, callback }, rootState) {
            dispatch.homeSection3.setLoading(true);
            updateCharacteristic(id, data)
                .then(({ data }) => {
                    callback();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.homeSection3.setLoading(false));
        },
        async deleteCharacteristic(id, rootState) {
            dispatch.homeSection3.setLoading(true);
            deleteCharacteristic(id)
            .then(({ data }) => {
                    console.log(data);
                    dispatch.homeSection3.getAll();
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                }).then(() => dispatch.homeSection3.setLoading(false));
        },
        async getCharacteristicById(id, rootState) {
            getCharacteristicById(id)
                .then(({ data }) => {
                    dispatch.homeSection3.setItemSelected(data);
                }).catch((err) => {
                    console.error('Error', err);
                    if (err.request.status === 401) {
                        dispatch.auth.logout();
                    }
                });
        }
    })
}

export default model;
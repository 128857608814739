import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '../../shared';
import { DialogContext } from '../../../context/dialog';
import { UserSchema } from "../../../formSchemas";

const Form = () => {
    const { selectedUser, loading } = useSelector(state => state.users);
    const { setDialogContent } = useContext(DialogContext);
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(UserSchema), mode: 'all' });
    const dispatch = useDispatch();

    const cancelHandler = () => {
        setDialogContent(null);
    }

    const onSubmit = (data) => {
        if (selectedUser) {
            dispatch.users.updateUser({id: selectedUser.id, callback: () => {
                reset();
                cancelHandler();
            }, ...data});
        } else {
            dispatch.users.createUser({...data, callback: () => {
                reset();
                cancelHandler();
            }});
        }
    }

    useEffect(() => {
        if (selectedUser) {
            reset({
                email: selectedUser.email,
                password: selectedUser.password
            });
        }
        // eslint-disable-next-line
    }, [selectedUser])

    useEffect(() => {
        return () => {
            reset();
            dispatch.users.setSelectedUser(null);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-2/5 bg-white px-12 py-6 shadow-lg rounded">
            <TextField
                ref={register}
                label="Email"
                name="email"
                error={errors?.email?.message} />
            <TextField
                ref={register}
                label="Contraseña"
                name="password"
                error={errors?.password?.message}
                type="password" />

            <div className="mt-8 flex justify-between align-center">
                <button
                    type="button"
                    className="py-2 px-6 border border-red-500 text-red-500 rounded text-white"
                    onClick={cancelHandler}
                    disabled={loading}>
                        Cancelar
                    </button>
                <button
                    type="submit"
                    className="py-2 px-6 ml-2 bg-primary rounded text-white"
                    disabled={loading}>
                        {loading ? 'Guardando' : 'Guardar'}
                    </button>
            </div>
        </form>
    );
}

export default Form;
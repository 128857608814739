import React, { memo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DetailSliderSchema } from '../../../formSchemas';
import { useImage } from '../../../hooks';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(DetailSliderSchema), mode: 'all' });
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, itemSelected, slideUpdatedSuccessfull } = useSelector((state) => state.detailSlider);
    const { slideId, modelId } = useParams();
    const { imageSrc: mobileImageUrl, onImageChange: onMobileImageChange, error: mobileImageError } = useImage({ maxSize: 1 });
    const { imageSrc: desktopImageUrl, onImageChange: onDesktopImageChange, error: desktopImageError } = useImage({ maxSize: 1 });

    const onSubmit = useCallback((data) => {
        const formData = new FormData();
        const { mobileImage, desktopImage, ...noImageProperties } = data;

        if (mobileImage[0]) formData.append('mobileImage', new Blob([mobileImage[0], { type: mobileImage[0].type }]));
        if (desktopImage[0]) formData.append('desktopImage', new Blob([desktopImage[0], { type: desktopImage[0].type }]));
        
        for (const key in noImageProperties) {
            formData.append(key, noImageProperties[key]);
        }

        formData.append('modelId', modelId);

        if (slideId) dispatch.detailSlider.updateModelDetailSlide({ id: slideId, data: formData }); 
        else dispatch.detailSlider.createModelDetailSlide({ data: formData });
    }, [dispatch, slideId, modelId]);

    const onImageContainerClick = useCallback((elementId) => {
        document.getElementById(elementId).click();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (slideUpdatedSuccessfull) {
            setTimeout(() => {
                dispatch.detailSlider.setSlideUpdatedSuccessfull(false);
                history.push('/detail-slider');
            }, 3000);
        }
        // eslint-disable-next-line
    }, [slideUpdatedSuccessfull]);

    useEffect(() => {
        if (slideId) dispatch.detailSlider.getModelDetailSlideById(slideId);
        // eslint-disable-next-line
    }, [slideId]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                title: itemSelected.title,
                subtitle: itemSelected.subtitle,
                secondaryText: itemSelected.secondaryText,
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    return (
        <div className="px-4 py-1 bg-white rounded shadow">
            <h2 className="text-2xl py-4">
                {itemSelected ? 'Editar' : 'Agregar'} slide {itemSelected ? ` - ${itemSelected?.model.model}` : ''} (Detalle de modelo)
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label="Titulo"
                    name="title"
                    ref={register}
                    error={errors?.title?.message}
                    placeholder="Escribe el titulo" />
                
                <TextField
                    label="Subtitulo"
                    name="subtitle"
                    ref={register}
                    error={errors?.subtitle?.message}
                    placeholder="Escribe el subtitulo" />
                
                <TextField
                    label="Texto secundario"
                    name="secondaryText"
                    ref={register}
                    error={errors?.secondaryText?.message}
                    placeholder="Escribe el texto secundario" />

                <div className="flex my-8">
                    <div className="image-container w-1/2 px-4 flex flex-col items-center text-center">
                        <div
                            className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                            style={{backgroundImage: `url(${mobileImageUrl || itemSelected?.mobileImage})`}}
                            onClick={() => onImageContainerClick('mobileImage')}>
                            <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                Sube tu imagen - Mobile (Click aquí)
                            </div>
                        </div>
                        <span className="text-xs text-left text-red-500 mt-2 pr-6">{mobileImageError}</span>
                        <input
                            className="hidden"
                            id="mobileImage"
                            type="file"
                            name="mobileImage"
                            ref={register()}
                            onChange={onMobileImageChange}
                            accept="image/jpeg, image/jpg" />
                    </div>
                    <div className="image-container w-1/2 px-4 flex flex-col items-center text-center">
                        <div
                            className="font-bold text-white h-48 w-48 bg-gray-200 rounded cursor-pointer bg-cover bg-center bg-no-repeat"
                            style={{backgroundImage: `url(${desktopImageUrl})`}}
                            onClick={() => onImageContainerClick('desktopImage')}>
                            <div className="flex justify-center items-center text-center bg-black w-full h-full p-4 bg-opacity-40">
                                Sube tu imagen - Escritorio (Click aquí)
                            </div>
                        </div>
                        <span className="text-xs text-left text-red-500 mt-2 pr-6">{desktopImageError}</span>
                        <input
                            className="hidden"
                            id="desktopImage"
                            type="file"
                            name="desktopImage"
                            ref={register()}
                            onChange={onDesktopImageChange}
                            accept="image/jpeg, image/jpg" />
                    </div>
                </div>

                {slideUpdatedSuccessfull && <div className="p-4 bg-green-500 text-white rounded my-8">Slide {itemSelected ? 'editado' : 'creado'} satisfactoriamente.</div>}

                {!slideUpdatedSuccessfull && <div className="flex justify-end w-full my-2">
                    <button
                        type="button"
                        className="py-2 px-6 ml-2 border border-red-500 text-red-500 rounded text-white"
                        disabled={loading}
                        onClick={() => history.push('/detail-slider')}>Cancelar</button>
                    <button
                        type="submit"
                        className="py-2 px-6 ml-2 bg-primary rounded text-white"
                        disabled={loading}>
                        {loading ? 'Guardando' : 'Guardar'}
                    </button>
                </div>}
            </form>
        </div>
    );
});

export default Form;
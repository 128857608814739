import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { preownedBanner2Schema } from '../../../formSchemas';
import PreownedBanner2 from '../../../assets/img/sections/seminuevos/banner-2.png';

const SLUG = 'preowned-banner-2';

const Form = memo(() => {
    const { handleSubmit, register, errors, reset } = useForm({ resolver: yupResolver(preownedBanner2Schema), mode: 'all' });
    const dispatch = useDispatch();
    const { loading, itemSelected, itemUpdatedSuccessfully } = useSelector((state) => state.preownedBanner2);

    const onSubmit = useCallback((data) => {

        const callback = () => {
            setTimeout(() => {
                dispatch.preownedBanner2.setItemUpdatedSuccessfully(false);
            }, 3000);
        };

        dispatch.preownedBanner2.updateSectionData({ slug: SLUG, data, callback }); 
    }, [dispatch]);

    useEffect(() => {
        if (itemSelected) {
            reset({
                title: itemSelected.title,
                subtitle: itemSelected.subtitle
            });
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        dispatch.preownedBanner2.getSectionData(SLUG);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div
                className="w-full h-72 bg-cover bg-no-repeat bg-center"
                style={{backgroundImage: `url(${PreownedBanner2})`}} />
            <div className="px-4">
                <div className="px-4 py-1 bg-white rounded shadow -mt-8">
                    <h2 className="text-3xl py-4 font-bold text-primaryDark">Banner 2 (Seminuevos)</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            label="Titulo"
                            name="title"
                            ref={register}
                            error={errors?.title?.message}
                            placeholder="Escribe el titulo del banner" />
                        
                        <TextField
                            label="Subtitulo"
                            name="subtitle"
                            ref={register}
                            error={errors?.title?.message}
                            placeholder="Escribe el subtitulo del banner" />

                        {itemUpdatedSuccessfully && <div className="p-4 bg-green-500 text-white rounded my-8">Banner editado satisfactoriamente.</div>}

                        {!itemUpdatedSuccessfully && <div className="flex justify-end w-full my-2">
                            {/* <button
                                type="button"
                                className="py-2 px-6 ml-2 border border-red-500 text-red-500 rounded text-white"
                                disabled={loading}>Cancelar</button> */}
                            <button
                                type="submit"
                                className={`${loading ? 'bg-gray-400' : 'bg-primary'} py-2 px-6 ml-2 rounded text-white`}
                                disabled={loading}>
                                {loading ? 'Guardando...' : 'Guardar'}
                            </button>
                        </div>}
                    </form>
                </div>
            </div>
        </>
    );
});

export default Form;